* {
    margin: 0;
}

body {
    background-color: #444444;
}

#root, #vjs-video, video {
    height: 100vh;
    max-height: 100vh;
}

#vjs-video {
    position: relative;
    padding: 0;
}